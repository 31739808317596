.sec-somos-top {
	@include bg-top-page('andari-somo-top2.jpg');
}

.pag-somos {
	.sec-page-top {
		p {
			margin-left: 0; width: 26.5rem;

			@media all and (max-width: map-get($grid-breakpoints, 'sm') - 1) {
				width: 100%;
			}

		}

	}
}


.sec-vision {
	margin-top: 4.4rem;

	p { @include p-default(); }
}

.sec-valores {
	margin: 8.125rem 0 7.625rem;

	ul { margin: auto;

		@media all and (max-width: map-get($grid-breakpoints, 'lg') - 1) {
			width: 50%;
		}

		@media all and (max-width: map-get($grid-breakpoints, 'md') - 1) {
			width: 100%;
		}
	}

	li { bottom: -1000px; position: relative; transform: translate(0, 0);

		&.on-item {
			&:nth-child(1) { bottom: 0; transition: all 0.7s ease-in-out; }

			&:nth-child(2) { bottom: 0; transition: all 1.4s ease-in-out; }

			&:nth-child(3) { bottom: 0; transition: all 2.1s ease-in-out; }

			&:nth-child(4) { bottom: 0; transition: all 2.8s ease-in-out; }
		}
	}

}

	// li { width: 60%; }

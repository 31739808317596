.sec-home-top {
	@include bg-top-page("stamp-home.png");
}
.sec-como-top {
	@include bg-top-page("stamp-como.png");
}
.sec-serv-top {
	@include bg-top-page("stamp-serv.png");
}
.sec-osomos-top {
	@include bg-top-page("stamp-somos.png");
}
.sec-contact-main {
	@include bg-top-page("stamp-contact.png");
	.container-fluid {
		background-position: center !important;
	}
}

.sec-home-top {
	.custom-col {
		max-width: 1100px;
		flex: 1;
		margin-top: 7rem;
		.button-black{
			margin-right: 2rem;
		}
	}
}
// ****************************
// conexiones
// ****************************
.sec-conexiones {
	margin-top: 4.3rem;
	overflow: hidden;
	padding: 0 2.5625rem 0;

	.sec-subtitle {
		@include txt-font-40($section-subtitle, 40px);
		font-weight: 800;
		letter-spacing: -1.11px;
		margin-bottom: 4.7rem;
		text-align: center;
		text-transform: uppercase;
	}
	.us-desc {
		text-align: left;
		position: absolute;
		top: 410px;
		padding: 0 10px;
		.subtitle-line {
			margin-bottom: 0;
			padding-bottom: 0;
			line-height: 0.9;
		}
		p {
			font-size: 21px;
		}
		@media all and (max-width: map-get($grid-breakpoints, "sm") - 1) {
			position: initial;
		}
	}
	.us-icons {
		margin-top: 120px;
		.d-flex {
			div {
				@media all and (max-width: map-get($grid-breakpoints, "sm") - 1) {
					margin-top: 2rem;
				}
			}
		}
	}
}

.quienes-text {
	display: flex;
	margin-bottom: 4rem;
	margin-left: auto;
	margin-right: auto;
	img {
		max-width: 454px;
	}
	.quien-desc {
		color: $white;
		text-align: left;
		padding-right: 3rem;
		padding-left: 1rem;
		background: url(#{$_image-path}/bkg/stamp-quienes-back.svg) no-repeat right;
		.subtitle-line {
			margin-left: -2.6rem;
			padding-bottom: 0;
			color: $white;
			margin-top: 2rem;
		}
		.button {
			background: transparent;
			border: 2px solid $color-primary;
			color: $color-primary;
			box-shadow: none;
		}
	}
}

.wrapper-animation {
	justify-content: center;
	margin: 0 auto 3.7rem auto;

	.d-flex {
		justify-content: center;
		position: relative;
		align-items: flex-end;
		justify-content: space-around;
	}

	h5 {
		line-height: 1;
		color: $brand-secondary;
		font-weight: 600;
		font-size: 38px;
		letter-spacing: -0.92px;
		order: 2;
		padding-top: 1rem;
		text-align: center;
		text-transform: uppercase;
	}
}

.mv-and {
	@include animate-elm();
	bottom: 2000px;
	display: flex;
	flex-direction: column;
	position: relative;
	flex: 1;

	svg {
		height: 11.5625rem !important;
		margin: auto;
		width: 11.5625rem !important;
	}

	&:nth-child(1) {
		padding-right: 3.3rem;

		@media all and (max-width: map-get($grid-breakpoints, "sm") - 1) {
			padding-right: 0;
		}
	}

	&:nth-child(2) {
		-webkit-animation-delay: 0.7s;
		animation-delay: 0.7s;
		padding-right: 3.3rem;

		@media all and (max-width: map-get($grid-breakpoints, "sm") - 1) {
			margin: 2rem auto;
			padding-right: 0;
		}
	}

	&:nth-child(3) {
		-webkit-animation-delay: 1.4s;
		animation-delay: 1.4s;
	}

	&.on-item {
		-webkit-animation-name: bounce-in-up;
		animation-name: bounce-in-up;
		bottom: 0;
		position: relative;

		&:nth-child(1) {
			&::after {
				-webkit-animation: 1.3s ease 0s normal forwards 1 fadein;
				animation: 1.3s ease 0s normal forwards 1 fadein;
				opacity: 1;
			}
		}

		&:nth-child(2) {
			&::after {
				-webkit-animation: 1.7s ease 0s normal forwards 1 fadein;
				animation: 1.7s ease 0s normal forwards 1 fadein;
				opacity: 1;
			}
		}
	}
}

// ****************************
// sec-servicios
// ****************************
.sec-servicios {
	overflow: hidden;
	background: $white;

	.col-lg-3 {
		min-height: 360px;
		display: flex;
		align-items: flex-end;
		padding: 1rem;
		&.h-one {
			background: url(#{$_image-path}/bkg/stamp-back-1.jpg) no-repeat;
			background-size: cover;
		}
		&.h-two {
			background: url(#{$_image-path}/bkg/stamp-back-2.jpg) no-repeat;
			background-size: cover;
		}
		&.h-three {
			background: url(#{$_image-path}/bkg/stamp-back-3.jpg) no-repeat;
			background-size: cover;
		}
		&.h-four {
			background: url(#{$_image-path}/bkg/stamp-back-4.jpg) no-repeat;
			background-size: cover;
		}
		p {
			color: $white;
			text-align: left;
			font-size: $font-size-25;
			font-weight: 600;
		}
		a {
			color: $white;
			text-align: right;
			width: 100%;
			display: block;
			font-size: $font-size-20;
			font-weight: 100;
			padding-right: 3rem;
			background: url(#{$_image-path}/bkg/arrow-r.png) no-repeat right center;
		}
	}

	.col-lg-6.back-img {
		background: url(#{$_image-path}/bkg/fedi-servicios.jpg) no-repeat center bottom;
		background-size: cover;
	}

	.container-fluid {
		max-width: 100%;
		margin: 0;
		padding: 0;
		width: 100%;
	}

	.sec-subtitle {
		@include txt-font-40($section-subtitle, 40px);
		font-weight: 800;
		letter-spacing: -1.11px;
		text-align: center;
		text-transform: uppercase;
	}

	.row {
		padding-top: 1.8rem;
		position: relative;
	}

	h3 {
		@include txt-font-30($section-subtitle, 800);
		background: url(#{$_image-path}/bkg/ondas-title-pink.svg) no-repeat center bottom;
		display: inline-flex;
		letter-spacing: -0.83px;
		line-height: 38px;
		margin-bottom: 0.8rem;
		padding-bottom: 1.28rem;
		text-align: center;
		text-transform: uppercase;
	}

	p {
		@include txt-font-18($text-gral, 500);
		letter-spacing: -0.5px;
		line-height: 21px;
		margin: 0 auto 0.5rem;
		position: relative;
		text-align: center;
		z-index: 5;
	}

	.button {
		margin-top: 1.5rem;
	}
}

// ****************************
// sec-testimonios
// ****************************
.sec-testimonios {
	background: $bg-testimonios;
	height: 456px;
	margin-top: 4.5rem;
	padding-top: 4.375rem;
	position: relative;

	&::before {
		@include circle-lateral();
		left: 0;
		transform: translate(-50%, -50%);
	}

	&::after {
		@include circle-lateral();
		right: 0;
		transform: translate(50%, -50%);
	}

	.swiper-pagination {
		bottom: 90px;
		pointer-events: fill;
	}

	.container-fluid {
		height: 100%;
	}

	.swiper-button-prev,
	.swiper-button-next {
		display: none;
	}

	.swiper-container {
		height: 456px;
		pointer-events: none;
	}

	.swiper-pagination-bullet {
		background: $bg-pink-1;
		border: 0;
		height: 0.7rem;
		margin: 0.3rem;
		opacity: 0.8;
		width: 0.7rem;

		&.swiper-pagination-bullet-active  {
			opacity: 1;
		}
	}

	h3 {
		@include txt-font-50($brand-primary, $font-size-50);
		@include line-subtitle();
		display: inline-flex;
		font-weight: 900;
		letter-spacing: -1.39px;
		line-height: 63px;
		margin: 0 auto 2.5rem;
		text-align: center;
		text-transform: uppercase;
		word-break: break-all;
	}

	p {
		@include txt-font-16($text-gral);
		border-bottom: solid 1px $brand-accent;
		font-weight: 500;
		letter-spacing: -0.47px;
		margin-bottom: 1.3rem;
		padding-bottom: 1.5625rem;
		width: 60.5%;

		@media all and (max-width: map-get($grid-breakpoints, "md") - 1) {
			width: 100%;
		}
	}

	h6 {
		@include txt-font-16($text-gral);
		font-style: italic;
	}
}

.sec-home {
	.sec-conexiones {
		margin-top: 0;
		min-height: 12rem;
		background: $black;
		padding: 4rem 0;
		h2{
			font-size: 30px;
			font-weight: 100;
			color: $white;
			max-width: 600px;
    margin: 2rem auto;
			span{
				font-weight: 800;
			}
		}
	}

	.sec-page-top {
		padding: 0;
		p:last-of-type{
			display: flex;
			gap: 2rem;
			margin-top: 2rem;
		}
	}

	.sec-servicios {
		h3 {
			@media all and (max-width: map-get($grid-breakpoints, "md") - 1) {
				margin-top: 30px;
			}
		}
	}
}

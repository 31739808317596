/*
 * SCSS THEME BASIC TAGS File
 * by SalsaLab
 */
h1,
h2,
h3,
h4,
h5,
h6 {
	margin: 0;
	font-family: $font-family-base;
}
h2 {
	font-weight: 800;
}
body {
	background: $body-bg;
	display: flex;
	flex-direction: column;
	font-size: 100%;
	font-weight: 300;
	justify-content: space-between;
	min-height: 100vh;
	overflow-x: hidden;
	position: relative;

	color: $light-black;
	font-family: $font-family-base;
	font-size: 21px;
	letter-spacing: 0.23px;
	line-height: 24px;
	outline: none;

	.no-click {
		pointer-events: none;
		cursor: default;
		display: none;
	}
}

main {
	margin-bottom: 2rem;
}
html {
	font-size: 100%;
	line-height: 16px;
}
$font-basic: $font-family-base !default;

@mixin infinite-anm() {
	-webkit-animation-iteration-count: infinite;
	animation-iteration-count: infinite;
}

@mixin txt-font-12($color-txt, $fw, $lh: $font-size-12) {
	color: $color-txt;
	font-size: $font-size-12;
	font-weight: $fw;
	line-height: $font-size-12;
}

@mixin txt-font-14($color-txt, $fw, $lh: $font-size-14) {
	color: $color-txt;
	font-size: $font-size-14;
	font-weight: $fw;

	@if $lh {
		line-height: $lh;
	} @else {
		line-height: $font-size-14;
	}
}

@mixin txt-font-16($color-txt, $lh: $font-size-16) {
	@if $lh {
		line-height: $lh;
	} @else {
		line-height: $font-size-16;
	}

	color: $color-txt;
	font-size: $font-size-16;
}

@mixin txt-font-18($color-txt, $fw, $lh: $font-size-18) {
	color: $color-txt;
	font-size: $font-size-18;
	font-weight: $fw;
	line-height: $lh;
}

@mixin txt-font-20($color-txt, $fw, $lh: $font-size-20) {
	@if $lh {
		line-height: $lh;
	} @else {
		line-height: $font-size-18;
	}

	color: $color-txt;
	font-size: $font-size-20;
	font-weight: $fw;
}

@mixin txt-font-25($color-txt, $fw, $lh: $font-size-25) {
	@if $lh {
		line-height: $lh;
	} @else {
		line-height: $font-size-18;
	}

	color: $color-txt;
	font-size: $font-size-25;
	font-weight: $fw;
}

@mixin txt-font-28($color-txt, $fw, $lh: $font-size-28) {
	color: $black;
	font-size: $font-size-28;
	font-weight: $fw;
	line-height: $font-size-28;

	// @media all and (max-width: map-get($grid-breakpoints, "sm")) {
	// 	color: $color-txt; font-size: 1.5rem; line-height: 1.5rem;
	// }
}

@mixin txt-font-30($color-txt, $fw: 400) {
	color: $color-txt;
	font-size: $font-size-30;
	font-family: $font-family-heading;
	line-height: 31px;

	@if $fw {
		font-weight: $fw;
	} @else {
		font-weight: 400;
	}

	@media all and (max-width: map-get($grid-breakpoints, "sm") - 1) {
		font-size: 24px;
		line-height: 25px;
	}
}

@mixin txt-font-40($color-txt, $lh: $font-size-40) {
	color: $color-txt;
	font-size: $font-size-40;
	font-family: $font-family-heading;
	line-height: $font-size-40;
}

@mixin txt-font-75($color-txt, $font-size-75) {
	color: $color-txt;
	font-size: $font-size-75;
	font-family: $font-family-heading;
	line-height: $font-size-75;
}

@mixin txt-font-45($color-txt) {
	color: $color-txt;
	font-size: 45px;
	font-family: $font-family-heading;
	line-height: 46px;
}

@mixin txt-font-50($color-txt, $lh: $font-size-50) {
	color: $color-txt;
	font-size: $font-size-50;
	font-family: $font-family-heading;
	line-height: 3.6rem;
}

@mixin subtitle($txt-align) {
	@include txt-font-30($section-subtitle);
	font-family: $font-family-heading;
	letter-spacing: -0.83px;
	text-align: $txt-align;
}

.title-f30 {
	h3 {
		@include txt-font-30($section-subtitle, 800);
		font-family: $font-family-heading;
		letter-spacing: -0.83px;
		text-align: center;
		text-transform: uppercase;
	}
}

@mixin p-default() {
	@include txt-font-18($text-gral, 500);
	letter-spacing: -0.5px;
	line-height: 21px;
	text-align: center;
}

.wavy {
	border: 0;
	padding: 2.8rem 10rem;
	height: 30px;
	background: url(#{$_image-path}/bkg/stamp-line-subtitle.svg) no-repeat;
	background-position: center;
}

.container-fluid {
	max-width: 1100px;
}

.button {
	@include txt-font-14($white, 500, 16px);
	letter-spacing: 1.9px;
	background-color: transparent;

	&:active,
	&:hover {
		background: transparent;
		border-color: $black;
		color: $black;

		&::after {
			color: $white;
			display: block;
			padding-left: 0.5rem;
		}
	}

	&.negative {
		background: $black;
		color: $white;

		&:hover {
			border: 2px solid $black;
			background: transparent;
			color: $black;
		}
	}
}
.button.color-alt {
	background: $color-alt;
	border: solid 2px $color-alt;
	@include txt-font-18($white, 500, 18px);
	&:active,
	&:hover {
		background: transparent;
		border-color: $color-alt;
		color: $color-alt;

		&::after {
			color: $white;
			display: block;
			padding-left: 0.5rem;
		}
	}
}

@mixin container-no-max() {
	.container-fluid {
		max-width: 100%;
		padding: 0;
	}
}

@mixin in-project() {
	position: relative;
	z-index: 7;
}

@mixin link-more() {
	font-family: $font-family-light;
	font-size: $font-size-12;
	font-weight: 300;
	line-height: $line-height-14;
	text-decoration: none;
	text-transform: uppercase;
}

@mixin line-subtitle() {
	position: relative;
}

@mixin bg-line-primary() {
	background: $black;
}

@mixin circle-lateral() {
	background: $color-secondary;
	border-radius: 50%;
	content: " ";
	display: block;
	height: 9.5rem;
	position: absolute;
	top: 50%;
	width: 9.5rem;
}

.ico-md.p0 {
	&::before {
		padding: 0;
	}
}
.sec-como-top {
	.custom-col {
		max-width: 1100px;
		-webkit-box-flex: 1;
		-ms-flex: 1;
		flex: 1;
		margin-top: 7rem;
		h3 {
			color: $black;
			font-size: 75px;
		}
		h2 {
			margin-top: 0;
			color: $black;
			font-size: 30px;
			max-width: 590px;
		}
	}
}
.sec-serv-top {
	.custom-col {
		max-width: 1100px;
		-webkit-box-flex: 1;
		-ms-flex: 1;
		flex: 1;
		margin-top: 7rem;
		h3 {
			color: $black;
			font-size: 75px;
		}
		h2 {
			margin-top: 0;
			color: $black;
			font-size: 30px;
			max-width: 590px;
		}
	}
}
.sec-osomos-top {
	.custom-col {
		max-width: 1100px;
		-webkit-box-flex: 1;
		-ms-flex: 1;
		flex: 1;
		margin-top: 7rem;
		h3 {
			color: $black;
			font-size: 75px;
		}
		h2 {
			margin-top: 0;
			color: $black;
			font-size: 30px;
			max-width: 590px;
		}
	}
}

.button {
	@include txt-font-16($white, 24px);
	align-items: center;
	display: inline-flex;
	font-weight: 600;
	justify-content: center;
	min-height: 1.6875rem;
	min-width: 7.668125rem;
	padding: 10px 30px;
	text-shadow: 0 1px 0 0 $white;
	text-transform: uppercase;
}

.bg-dark {
	background: $gray-dark;
}

.bg-primary {
	background: $brand-primary;
}

.txt-center {
	text-align: center;
}

@-webkit-keyframes rightleft {
	0% {
		background-position: 100% 0%;
	}
	50% {
		background-position: 50% 100%;
	}
	100% {
		background-position: 0% 50%;
	}
}

@-moz-keyframes rightleft {
	0% {
		background-position: 100% 0%;
	}
	50% {
		background-position: 50% 100%;
	}
	100% {
		background-position: 0% 50%;
	}
}

@keyframes rightleft {
	0% {
		background-position: 100% 0%;
	}
	50% {
		background-position: 50% 100%;
	}
	100% {
		background-position: 0% 50%;
	}
}

@-webkit-keyframes Gradient {
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
}

@-moz-keyframes Gradient {
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
}

@keyframes Gradient {
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
}

@-webkit-keyframes gradient-bt {
	0% {
		background-position: 0% 0%;
	}
	50% {
		background-position: 0% 50%;
	}
	100% {
		background-position: 0% 100%;
	}
}

@-moz-keyframes gradient-bt {
	0% {
		background-position: 0% 0%;
	}
	50% {
		background-position: 0% 50%;
	}
	100% {
		background-position: 0% 100%;
	}
}

@keyframes gradient-bt {
	0% {
		background-position: 0% 0%;
	}
	50% {
		background-position: 0% 50%;
	}
	100% {
		background-position: 0% 100%;
	}
}

@mixin bg-service-animate($gradient-bt, $sx, $sy) {
	background-repeat: no-repeat;
	background-size: $sx $sy;
	-webkit-animation: $gradient-bt 15s ease infinite;
	-moz-animation: $gradient-bt 15s ease infinite;
	animation: $gradient-bt 15s ease infinite;
}

@mixin title-primary() {
	display: inline-flex;
	flex-direction: column;
	margin: 4.375rem 0 1.4rem;

	&::after {
		background: $primary-2;
		content: " ";
		height: 7px;
		margin-top: 1.3rem;
		width: 100%;
	}
}

@mixin product-line($bg-product, $bg-line) {
	h3 {
		display: inline-flex;
		flex-direction: column;
		margin: 4.375rem 0 0;

		&::after {
			@include animate-elm();
			background: $bg-product;
			content: " ";
			height: 1.5625rem;
			left: -0.9375rem;
			position: relative;
			top: -2.2rem;
			width: 100%;
			z-index: -2;
		}

		&.on-item {
			&::after  {
				-webkit-animation-name: bounce-in-right;
				animation-name: bounce-in-right;
			}
		}
	}

	p {
		border-top: 3px solid $bg-line;
		margin: 0;
		padding-top: 1rem;
	}
}

@mixin bg-onda-tb {
	animation: slide 60s linear infinite;
	background: url(#{$_image-path}/bkg/textura-ondas-rosas.svg) repeat center 15px;
	content: " ";
	display: block;
	height: 30px;
	left: 0;
	position: absolute;
	width: 1000%;
}

.full-width {
	.container-fluid {
		max-width: 100%;
		padding: 0;

		.row {
			width: 100%;
		}
	}
}

.intro-default {
	.content-media {
		p {
			align-items: center;
			background: $section-subtitle;
			border-radius: 50%;
			display: flex;
			height: 16.875rem;
			text-align: center;
			width: 16.875rem;
		}

		img {
			margin: auto;
		}
	}

	h2 {
		@include txt-font-25($section-subtitle, 800);
		font-family: $font-family-heading;
		letter-spacing: -0.69px;
		text-align: left;
		width: 27.5625rem;

		@media all and (max-width: map-get($grid-breakpoints, "lg") - 1) {
			text-align: center;
		}
	}

	.content-text {
		@media all and (max-width: map-get($grid-breakpoints, "lg") - 1) {
			align-items: center;
			display: flex;
			flex-direction: column;
			justify-content: center;
		}

		p {
			@include p-default();
			letter-spacing: -0.5px;
			margin-left: 0;
			text-align: left;

			@media all and (min-width: map-get($grid-breakpoints, "sm") + 1) {
				width: 65%;
			}

			@media all and (max-width: map-get($grid-breakpoints, "lg") - 1) {
				margin: auto;
			}

			&:first-of-type {
				background-image: url(#{$_image-path}/bkg/linea2-blue.svg) no-repeat left bottom;
				padding-bottom: 2.2rem;
			}

			&:last-of-type {
				margin: 0;
			}
		}
	}
}

.item-squared {
	ul {
		margin: auto;
	}

	.subtitle {
		@include txt-font-30($section-subtitle, 800);
		font-family: $font-family-heading;
		letter-spacing: -0.83px;
		margin: 5rem auto 4rem;
		text-align: center;
		text-transform: uppercase;
		width: 50%;
	}

	li {
		margin-bottom: 0.3rem;

		&::before {
			background: $brand-accent;
			content: " ";
			display: block;
			height: 10px;
			left: -1.4rem;
			margin-right: 1.3125rem;
			position: absolute;
			top: 0.4rem;
			width: 10px;
		}
	}

	h4 {
		@include txt-font-20($text-gral, 500);
		letter-spacing: -0.56px;
		line-height: 24px;
		position: relative;
		text-align: left;

		strong {
			margin-right: 0.5rem;
		}
	}
}

@mixin iconli() {
	background-size: cover;
	content: " ";
	display: block;
	height: 84px;
	margin-right: 2.5%;
	position: static;
	width: 83px;

	@media all and (max-width: map-get($grid-breakpoints, "md") - 1) {
		height: 70px;
		width: 70px;
	}
}

.item-check {
	ul {
		padding-top: 1.5rem;
	}

	.subtitle-line {
		margin-bottom: 4.1875rem;
	}

	img {
		object-fit: cover;
		width: 100%;
	}

	p {
		margin: 0;
	}

	li {
		padding-left: 2rem;

		&::before {
			background: url(#{$_image-path}/bkg/icon-check.svg) no-repeat center;
			content: " ";
			display: block;
			height: 2.375rem;
			left: -2rem;
			margin-right: 1.3125rem;
			position: absolute;
			top: 0;
			width: 2.375rem;
		}
	}

	h4 {
		@include txt-font-20($text-gral, 500);
		letter-spacing: -0.56px;
		line-height: 24px;
		margin-bottom: 3rem;
		position: relative;
		text-align: left;

		strong {
			margin-right: 0.5rem;
		}
	}
}

.btn-stamp {
	font-size: 13px;
	text-transform: uppercase;
	border: 3px solid $black;
	padding: 0.5rem 2rem;
	font-weight: 700;
	display: block;
	width: fit-content;
	line-height: normal;
	background: transparent;
	&.button-black {
		background: $black;
		color: $white;
	}
	&.button-border {
		color: $black;
		background: transparent;
	}
	&.button-border-white {
		border-color: $white;
		color: $white;
		background: transparent;
	}
	&:focus{
		background: transparent;
	}
}

@mixin bg-top-page($imgbg) {
	$urlimg: "#{$_image-path}/bkg/#{$imgbg}";

	.container-fluid {
		background: url($urlimg) no-repeat right center;
		background-size: cover !important;
		background-position: right top;
		min-height: 80vh;
		max-width: 100%;
		display: flex;
		justify-content: center;
		flex-direction: column;

		@media all and (max-width: map-get($grid-breakpoints, "sm") - 1) {
			min-height: auto;
		}

		@media all and (max-width: map-get($grid-breakpoints, "xl") - 1) {
			background-size: 100%;
		}

		@media all and (max-width: map-get($grid-breakpoints, "lg") - 1) {
			background-image: none;
		}

		@media all and (max-width: map-get($grid-breakpoints, "lg") - 1) {
			background-size: 50%;
		}

		@media all and (max-width: map-get($grid-breakpoints, "md") - 1) {
			background: none;
		}
	}

	.row {
		@media all and (max-width: map-get($grid-breakpoints, "lg") - 1) {
			align-items: center;
			flex-direction: column;
		}

		&::after {
			@media all and (max-width: map-get($grid-breakpoints, "lg") - 1) {
				background: url($urlimg) no-repeat right center;
				background-size: cover;
				background-position: center;
				content: "andari";
				display: block;
				font-size: 0;
				height: 120px;
				width: 100%;
			}
		}
	}
}
.sec-como-funciona,
.sec-somos {
	main {
		padding-bottom: 3rem;
	}
	.sec-contacto {
		display: none;
	}
}
.blog-header,
#sidebar {
	.search-wrapper {
		opacity: 1;
		visibility: visible;
		max-height: initial;
	}
}
.search-wrapper {
	opacity: 0;
	visibility: hidden;
	max-height: 0;
	transition: max-height 0.3s ease-out, opacity 0.8s;
	overflow: hidden;

	input:not([type="radio"]):not([type="checkbox"]) {
		border-bottom: 0;
		background: $bg-light-gray url(#{$_image-path}/bkg/stamp-lupa.png) no-repeat 1rem center;
		padding-left: 4rem;
		color: $black-light;

		&::placeholder {
			color: $black-light;
			opacity: 1; /* Firefox */
		}
	}
}

.show-search {
	opacity: 1;
	visibility: visible;
	max-height: 500px;
	transition: max-height 0.8s ease-in, opacity 0.8s;
}

@media all and (max-width: map-get($grid-breakpoints, "sm") - 1) {
	.quienes-text {
		display: block !important;
		overflow: hidden;
		.quien-desc .subtitle-line {
			margin-left: 0 !important;
		}
	}
	.sec-clientes .row {
		display: block;
		padding-left: 2rem;
		.col-lg-3 {
			max-width: 64%;
			margin-bottom: 4rem;
			margin-left: auto;
			margin-right: auto !important;
		}
	}
	.sec-promosiones .col-lg-4 h2.price,
	.sec-promosiones .col-lg-4 h3.price {
		display: block;
	}
	.sec-ventajas {
		.row {
			display: block;
			.col-lg-3 {
				max-width: 100%;
			}
		}
	}
}

.blog-content-item {
	input:not([type="radio"]):not([type="checkbox"]):placeholder-shown:not(:focus) + label,
	select.invalid:not(:focus) + label,
	select:invalid:not(:focus) + label,
	textarea:placeholder-shown:not(:focus) + label {
		color: $color-primary;
		text-transform: uppercase;
		font-weight: 100;
	}
	input:not([type="radio"]):not([type="checkbox"]) + label,
	select + label,
	textarea + label {
		color: $color-primary;
		text-transform: uppercase;
		font-weight: 100;
	}
	input:-internal-autofill-selected {
		appearance: menulist-button;
		background-color: transparent !important;
	}

	input:not([type="radio"]):not([type="checkbox"]),
	select,
	textarea {
		color: #e0e0e0;
		border-bottom: 1px solid #e0e0e0;
	}

	.search-wrapper {
		form {
			width: 100%;
		}
	}
	h3 {
		font-size: 18px;
		font-weight: 700;
		color: #333;
	}
	form {
		width: 70%;
		& > div {
			&:first-of-type {
				margin-right: 2rem;
			}
			&:first-of-type,
			&:nth-child(2) {
				display: inline-block;
				width: 46.8%;
			}
		}
		.form-content {
			margin-top: 2rem;
		}
		.buttons {
			display: flex;
			justify-content: flex-end;
		}
	}
}
@media all and (max-width: map-get($grid-breakpoints, "md") + 1) {
	.quienes-text .quien-desc {
		padding: 1rem;
		background: $color-secondary !important;
	}
	.subtitle-line {
		font-size: 38px !important;
	}
	.contact-f p {
		text-align: center !important;
	}
	.sec-blog main.content .content-wrapper #listing,
	.sec-blog main.content .content-wrapper,
	.sec-blog main.content .posts-item-dest {
		display: block !important;
	}
	.sec-blog main.content .content-wrapper #listing {
		width: 90%;
		margin-left: auto;
		margin-right: auto;
	}
	.sec-blog main.content .content-wrapper #sidebar {
		width: 90% !important;
		margin-left: auto !important;
		margin-right: auto !important;
	}
	.sec-blog main.content .blog-header {
		padding: 4rem 3rem 10rem !important;
	}
}

@media (min-width: 320px) and (max-width: 600px) {
	.sec-clientes .carousel-item .inner-car .slide-caption.slide-caption-one h2 {
		font-size: 31px !important;
	  }
	  #carousel-five, #carousel-five .slide-image, #carousel-four, #carousel-four .slide-image, #carousel-second, #carousel-second .slide-image, #carousel-third, #carousel-third .slide-image {
		max-height: inherit !important;
		height: auto;
	  }
	.sec-cont {
		background: none !important;
	}
	.menu-mobile {
		color: #fff !important;
	}
	.sec-home-top .custom-col {
		margin-top: 3rem !important;
		margin-bottom: 3rem;
	}
	.sec-page-top p,
	.sec-page-top h3 {
		color: #000 !important;
		font-size: 45px !important;
		text-align: center !important;
	}
	.sec-page-top h4 {
		text-align: center !important;
	}
	.sec-home .sec-page-top p:last-of-type {
		flex-direction: column;
		align-items: center;
	}
	.sec-home-top .custom-col .button-black {
		margin-right: 0 !important;
	}
	.sec-clientes .carousel-item .inner-car {
		flex-direction: column;
		padding: 0 2rem;
	}
	.sec-clientes .carousel-item .inner-car .slide-image {
		margin: 2rem 0;
	}
	.sec-clientes .carousel-item .inner-car .slide-caption {
		margin-top: 0 !important;
	}
	.sec-clientes .container-fluid .slide-more {
		margin-top: 2rem !important;
		padding: 0 2rem;
	}
	.sec-clientes-dos .container-fluid {
		padding: 2rem 0 !important;
		flex-direction: column;
	}
	.carousel-control-next-icon,
	.carousel-control-prev-icon {
		background-image: none !important;
	}
	#gallery .galley-row {
		grid-template-columns: 1fr !important;
	}
	.footer .row {
		padding: 4rem 0 !important;
	}
	.sec-servicio .second-block {
		flex-direction: column;
		&.inverted-second {
			flex-direction: column-reverse;
		}
	}
	.sec-osomos-top .custom-col,
	.sec-como-top .custom-col,
	.sec-serv-top .custom-col {
		margin-top: 2rem !important;
		margin-bottom: 2rem;
		padding: 0 2rem;
	}
	.sec-somos .sec-servicio.alt {
		padding-bottom: 0 !important;
	}
	.sec-iconos .col-lg-12,
	.sec-somos-gallery .col-lg-12 {
		flex-direction: column;
		text-align: center;
	}
	.sec-cont main.content {
		flex-direction: column-reverse !important;
		padding: 0 2rem;
	}
	.sec-cont {
		.sec-page-top h3,
		.sec-page-top p {
			color: #fff !important;
			font-size: 25px !important;
			text-align: center !important;
		}
	}
	.sec-cont main.content .sec-page-top {
		margin-bottom: 2rem;
	}
	.social-icons {
		justify-content: center;
	}
}

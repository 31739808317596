.wrapper-footer {
	background: $black;
	color: $white;
}

.phone,
.email {
	@include txt-font-16($white);
	font-weight: 800;
	letter-spacing: -0.44px;
	line-height: 20px;
}

.copyright {
	border-top: 1px solid $links;
	padding-top: 0.5rem;

	p {
		@include txt-font-12($white, 800);
		font-weight: 500;
		letter-spacing: -0.33px;
		line-height: 14px;
		margin: 0;

		@media all and (max-width: map-get($grid-breakpoints, "sm") - 1) {
			padding: 0.5rem;
			text-align: center;
		}
	}
}

.footer-sm {
	.ico {
		color: $white;
	}

	&:last-of-type {
		margin-right: 0;
	}

	&::before {
		padding: 10px;
		font-size: 1.5rem;
	}

	&:hover {
		color: $brand-accent;
	}
	a {
		color: $white;
	}
}
.footer-sm-pr.ico::before {
    font-size: 2rem !important;
	padding-bottom: 5px;
}
.social-icons {
	text-align: right;
	height: 9rem;
	display: flex;
	align-items: center;
}
.logo-text {
	display: flex;
	flex-direction: column;
	align-items: center;
}
footer {
	.p-social {
		&:hover {
			color: $black;
		}
	}
	.footer-sm:hover {
		.p-social {
			color: $black;
		}
	}
}

.footer {
	position: relative;

	.row {
		justify-content: space-around;
		align-items: center;

		@media all and (max-width: map-get($grid-breakpoints, "md") - 1) {
			align-items: center;
			flex-direction: column;
			justify-content: center;
		}
	}

	.footer-sm {
		text-align: center;
	}
}

.contact-f {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;

	@media all and (max-width: map-get($grid-breakpoints, "sm") - 1) {
		flex-direction: column;
	}

	a:hover {
		color: $black-generic;
	}

	p {
		color: $black-generic;
		text-align: left;
	}
}

.link-privacidad {
	margin-right: 4rem;
	@include txt-font-20($black-generic, 100);
	line-height: 40px;
	letter-spacing: -0.33px;

	@media all and (max-width: map-get($grid-breakpoints, "md") + 1) {
		margin-left: auto;
		margin: 0 auto 1rem;
		display: block;
	}

	&:hover {
		@include txt-font-20($black-generic, 100);
		line-height: 40px;
	}
	&:last-of-type {
		margin-right: 0;
	}
}

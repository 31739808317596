// // ******************
// // contacto
// // ******************

.onload {
	.contact-form {
		&::after {
			content: " ";
			background: url(#{$_image-path}/bkg/ajax-loader.gif) no-repeat center center;
			display: block;
			height: 40px;
			width: 40px;
		}
	}
}

.content-form {
	padding: 3.7rem 0 3.2rem;
	margin-left: auto;
	margin-right: auto;

	textarea {
		height: 4.8125rem;
	}
}

.msg-succes {
	@include txt-font-28($white, 800);
	font-weight: 800;
	letter-spacing: -0.5px;
	margin: 2rem auto;
	text-align: center;
}

.title-contact {
	@include txt-font-50($brand-primary);
	font-weight: 900;
	letter-spacing: -1.39px;
	line-height: 63px;
	margin: auto;
	padding: 0.7rem 0;
	text-transform: uppercase;
	word-break: break-all;

	&::before {
		background: $lines-titles-2;
		content: " ";
		display: block;
		height: 8px;
		left: 0;
		position: absolute;
		right: 0;
		top: 0;
		width: 75px;
	}

	&::after {
		background: $lines-titles-2;
		bottom: 0;
		content: " ";
		display: block;
		height: 8px;
		left: 0;
		position: absolute;
		right: 0;
		width: 75px;
	}
}

.subtitle-contact {
	@include txt-font-28($white, 800);
	font-weight: 800;
	letter-spacing: -0.75px;
	margin: 2.3rem auto 0.5rem auto;
	position: relative;
	text-transform: uppercase;
	z-index: 10;
}

.text-contact {
	color: $black;
	font-size: 39px;
	font-weight: 800;
}
.text-contact-text {
	color: $black;
	font-size: 17px;
	font-weight: 100;
}

.contact-form {
	@media all and (min-width: map-get($grid-breakpoints, "md") + 1) {
		width: 358px;
		margin-left: auto;
		margin-right: auto;
	}

	@media all and (max-width: map-get($grid-breakpoints, "lg") - 1) {
		width: 358px;
		margin-left: auto;
		margin-right: auto;
	}

	@media all and (max-width: map-get($grid-breakpoints, "sm") - 1) {
		width: 100%;
	}

	input:not([type="radio"]):not([type="checkbox"]),
	select,
	textarea {
		margin-bottom: 1rem;
	}
	input:not([type="radio"]):not([type="checkbox"]),
	select,
	textarea {
		border: 1px solid $bg-gray;
		opacity: 0.7;
		color: $black;
		&:focus {
			outline: none;
			background: transparent;
			color: $black;
		}
	}
	textarea {
		min-height: 80px;
		&:focus {
			outline: none;
		}
	}
	input:placeholder-shown:not(:focus) + label {
		margin-left: 10px;
		color: $black;
	}
	textarea:placeholder-shown:not(:focus) + label {
		margin-left: 10px;
		color: $black;
	}
}
input:not([type="radio"]):not([type="checkbox"]):placeholder-shown:not(:focus) + label,
select.invalid:not(:focus) + label,
select:invalid:not(:focus) + label,
textarea:placeholder-shown:not(:focus) + label {
	padding: 0.8rem;
}

.form-content {
	order: 2;
}
.sec-cont {
	background: linear-gradient(90deg, rgba(255,255,255,1) 50%, rgba(0,0,0,1) 50%);
	main.content {
		display: flex;
		justify-content: space-between;
		flex-direction: row-reverse;
		max-width: 1100px;
		width: 100%;
		margin: auto;
		
		.sec-page-top {
			flex: 1;
			background: $black;
			padding: 2rem;
			.custom-col {
				h3,
				p {
					color: $white;
					line-height: normal;
				}
				h3 {
					font-size: 22px;
					font-weight: 800;
					margin-top: 4rem;
				}
				p {
					font-size: 17px;
					font-weight: 100;
				}
			}
		}
		.sec-contacto {
			.text-contact-text {
				margin-bottom: 4rem;
			}
			#contact-form {
				width: 100%;
			}
			flex: 1;
		}
	}
}
.social-icons{
	a{
		color: $white;
	}
}
.sec-contacto {
	input:not([type="radio"]):not([type="checkbox"]).invalid:not(:focus):not(:placeholder-shown),
	input:not([type="radio"]):not([type="checkbox"]):invalid:not(:focus):not(:placeholder-shown),
	textarea.invalid:not(:focus):not(:placeholder-shown),
	textarea:invalid:not(:focus):not(:placeholder-shown) {
		border-bottom-color: $bg-inputs;
	}

	input:not([type="radio"]):not([type="checkbox"]) + label,
	select + label,
	textarea + label {
		top: -1rem;
		color: $black;
	}

	input:not([type="radio"]):not([type="checkbox"]):placeholder-shown:not(:focus) + label,
	textarea:placeholder-shown:not(:focus) + label {
		color: $black-light;
		font-weight: 100;
	}

	textarea,
	input,
	label {
		font-family: $font-family-base;
		font-weight: 500;
		letter-spacing: -0.19px;
		line-height: 24px;
		text-transform: uppercase;
	}

	.buttons {
		width: 93%;
		.button {
			padding: 8px 50px;
			background: $black;
			border-color: $black;
			border-radius: 0;
			box-shadow: none;
			&:hover {
				background: transparent;
				border-width: 2px;
				color: $black;
			}
		}
	}
}

.contact-top {
	width: 27.9375rem;
	margin-bottom: 2rem;

	@media all and (max-width: map-get($grid-breakpoints, "sm") - 1) {
		width: 100%;
	}
}

.form-field.sectitle:nth-child(4n) {
	color: #000c56;
	font-weight: 800;
	text-transform: uppercase;
	margin-top: 40px;
}

.form-field.sectitle:nth-child(5n) {
	margin-bottom: 30px;
}
.sec-cont {
	.sec-contact-top {
		max-height: 600px;
		background-size: cover;
		min-height: 400px;
		.container-fluid {
			max-width: 100%;
		}
	}
}

section {
	background: $white url(#{$_image-path}/bkg/stamp-dot-back.png) left repeat-y,
		url(#{$_image-path}/bkg/stamp-dot-back.png) right repeat-y;
}
.fancy-sub {
	font-size: 23px;
	color: $color-primary;
}
.sec-page-top {
	overflow: hidden;

	p {
		@include txt-font-75($white, 75px);
		-webkit-animation-duration: 1s;
		-webkit-animation-fill-mode: both;
		animation-duration: 1s;
		animation-fill-mode: both;
		-webkit-animation: 2s ease 0s normal forwards 1 fadein;
		animation: 2s ease 0s normal forwards 1 fadein;
		opacity: 1;
		letter-spacing: -0.58px;
		text-align: left;
		margin: 0;
	}

	h3 {
		@include txt-font-75($white, 75px);
		font-weight: 900;
	}

	h4 {
		font-size: 25px;
		color: $black;
		font-weight: 100;
		span {
			font-weight: bolder;
		}
	}

	.slogan {
		color: $color-primary;
		font-size: 20px;
		margin-top: 0;
		font-weight: 300;
	}

	h2 {
		@include line-subtitle();
		font-size: $font-size-48;
		color: $color-primary;
		-webkit-animation-duration: 1s;
		-webkit-animation-fill-mode: both;
		animation-duration: 1s;
		animation-fill-mode: both;
		-webkit-animation-name: bounce-in-up;
		animation-name: bounce-in-up;
		letter-spacing: -1.39px;
		text-align: left;
		margin-top: 9rem;
		@media all and (max-width: map-get($grid-breakpoints, "sm") - 1) {
			@include txt-font-40($black);
			margin-top: 3rem !important;
		}
	}

	.button {
		@media all and (max-width: map-get($grid-breakpoints, "sm") - 1) {
			margin-bottom: 3rem;
		}
	}
}

.sec-empresas-top,
.sec-aduanas-top {
	h2 {
		&::after {
			background: $brand-accent;
			bottom: 12px;
			content: " ";
			display: block;
			height: 6px;
			left: 0;
			position: absolute;
			right: 0;
			width: 115px;
		}
	}
}
.sec-aduanas-top {
	h4 {
		max-width: 30%;
		font-weight: 600;
		font-size: 20px;
		@media all and (max-width: map-get($grid-breakpoints, "sm") - 1) {
			max-width: 100%;
		}
	}
}

.sec-map-top {
	h2 {
		@include line-subtitle();
		@include txt-font-50($brand-secondary, $font-size-45);
		font-size: $font-size-45;
		display: inline-flex;
		font-weight: 600;
		letter-spacing: -1.39px;
		margin: 3rem auto;
		text-transform: uppercase;
	}
}
.sec-servicios {
	.subtitle-line {
		font-weight: 600;
		@include txt-font-50($brand-secondary, $font-size-45);
		font-size: $font-size-45;
	}
}

.sec-ayuda-top,
.sec-servicios {
	h2 {
		@include line-subtitle();
		@include txt-font-50($brand-secondary, $font-size-35);
		font-size: $font-size-35;
		font-weight: 500;
		line-height: 2.6rem;
		letter-spacing: 1px;
		margin: 0 auto 3rem;
		text-transform: uppercase;
	}
}

.sec-servicios {
	h2 {
		margin-top: 3rem;
		padding-top: 0;
	}
}

.sec-promosiones {
	background: $black;
	padding: 6rem 0;
	p {
		text-align: left;
		color: $white;
		font-size: 17px;
		&.big-p {
			font-size: 30px;
			line-height: 1;
			span {
				font-weight: 800;
			}
		}
	}
}
.logos-grid {
	display: grid;
	grid-template-columns: 1fr 1fr 1fr;
	gap: 1rem;
	margin-top: 5rem;
}
.sec-como-funciona {
	.sec-internet.alt {
		position: relative;
		padding-bottom: 4rem;
		&:before,
		&:after {
			content: "";
			position: absolute;
			left: 0;
			right: 0;
			bottom: 25px;
			height: 200px;
			background: radial-gradient(closest-side, $color-primary, $color-primary 63%, transparent 65%);
			background-size: 146px 90px;
			background-position: 0px 169px;
			background-repeat: repeat-x;
		}
		&:after {
			background: radial-gradient(closest-side, transparent, transparent 60%, $color-primary 63%);
			background-size: 146px 90px;
			background-position: 73px -64px;
			background-repeat: repeat-x;
			bottom: -174px;
		}
	}
	#ventajas {
		position: relative;
		padding-bottom: 1px;
		background: $color-primary;
		&:before,
		&:after {
			content: "";
			position: absolute;
			left: 0;
			right: 0;
			bottom: 25px;
			height: 200px;
			background: radial-gradient(closest-side, $white, $white 63%, $color-primary 65%);
			background-size: 146px 90px;
			background-position: 0px 169px;
			background-repeat: repeat-x;
		}
		&:after {
			background: radial-gradient(closest-side, $color-primary, $color-primary 60%, $white 63%);
			background-size: 146px 90px;
			background-position: 73px -64px;
			background-repeat: repeat-x;
			bottom: -174px;
		}
	}
}

.logous {
	max-width: 140px;
}

.sec-clientes {
	&.sec-clientes-text {
		padding: 0;
	}
	padding: 3rem 0 0;
	.container-fluid {
		max-width: 100%;
		position: relative;
		padding: 0;
		#carouselExampleControls {
			max-width: 1100px;
			margin: auto;
			position: initial;
			.carousel-control-prev-icon,
			.carousel-control-next-icon {
				width: 90px;
				height: 90px;
			}
		}
		.slide-more {
			background: $black;
			margin-top: -4rem;
			.inner-slide-more {
				max-width: 1100px;
				margin: auto;
				padding: 2rem 0;
				color: $white;
				font-size: 17px;
				text-align: left;
				.the-socials {
					display: flex;
					gap: 1rem;
				}
			}
		}
		.slide-bottom-text {
			background: $white;
			box-shadow: 0px 40px 50px -32px rgba(0, 0, 0, 0.54) inset;
			-webkit-box-shadow: 0px 40px 50px -32px rgba(0, 0, 0, 0.54) inset;
			-moz-box-shadow: 0px 40px 50px -32px rgba(0, 0, 0, 0.54) inset;
			.inner-slide-bottom {
				max-width: 1100px;
				margin: auto;
				padding: 6rem 0;
				color: $black;
				font-size: 30px;
				text-align: center;
				p {
					margin: 0;
				}
			}
		}
	}
	.row {
		align-items: center;
	}
	.carousel-item {
		.inner-car {
			display: flex;
			justify-content: space-between;
			align-items: flex-start;
			column-gap: 2rem;
			position: initial;
			.slide-caption {
				flex: 0.5;
				text-align: left;
				display: flex;
				flex-direction: column;
				gap: 2rem;
				margin-top: 6rem;
				padding: 0 0.5rem;
				p {
					margin: 0;
				}
				&.slide-caption-one {
					h2 {
						font-size: 51px;
						font-weight: 900;
					}
					p {
						font-size: 25px;
					}
				}
				&.slide-caption-two {
					h2 {
						font-size: 75px;
						font-weight: 100;
						color: $light-black;
					}
					p {
						font-size: 17px;
					}
				}
			}
			.slide-image {
				flex: 1;
				img {
					width: 100%;
					height: auto;
				}
			}
		}
	}
	.col-lg {
		margin-left: auto;
		margin-right: auto;
		margin-bottom: 6rem;
		color: $white;
		padding: 2rem 0 5rem;
		background: $color-primary url(#{$_image-path}/bkg/stamp-dot-back-dos.png) left repeat;
		h2 {
			color: $white;
		}
		p {
			margin-top: 0;
		}
	}
	.row {
		max-width: 1100px;
		margin-right: auto;
		margin-left: auto;
		margin-top: -10rem;
	}
	.col-lg-3 {
		background: $white url(#{$_image-path}/bkg/client-dots.png) 230px 10px no-repeat;
		text-align: left;
		margin-right: 3rem;
		margin-top: 1rem;
		border-radius: 10px;
		padding: 0 1.6rem 0;
		flex: 0 0 30%;
		max-width: 30%;
		-webkit-box-shadow: 0px 1px 30px -8px rgba(0, 0, 0, 0.4);
		-moz-box-shadow: 0px 1px 30px -8px rgba(0, 0, 0, 0.4);
		box-shadow: 0px 1px 30px -8px rgba(0, 0, 0, 0.4);
		&:last-child {
			margin-right: 0;
		}

		.header-cli {
			display: flex;
			margin-left: -4rem;
			height: 68px;
			img {
				width: 87px;
				height: 87px;
				position: absolute;
				top: -10px;
			}
			div {
				p {
					margin-bottom: 0;
					padding-left: 6rem;
					&:last-of-type {
						margin-top: 0;
					}
					&:first-of-type {
						margin-top: 1.5rem;
						color: $color-primary;
						font-size: $font-size-20;
					}
				}
			}
		}
	}
}
#gallery {
	.col-lg-8 {
		margin: 2rem auto;
		display: flex;
		flex-direction: column;
		align-items: center;
		gap: 3rem;
	}
	.galley-row {
		display: grid;
		grid-template-columns: 1fr 1fr 1fr;
		height: 858px;
		overflow: hidden;
		position: relative;
		transition: height 1s;
		&::before {
			background: rgb(255, 255, 255);
			background: linear-gradient(0deg, rgba(255, 255, 255, 1) 0%, rgba(0, 0, 0, 0) 40%);
			content: "";
			position: absolute;
			bottom: 0;
			height: 100%;
			width: 100%;
			z-index: 9;
		}
		.col-lg {
			padding: 0;
			img {
				width: 357px;
				height: 286px;
			}
		}
		&.expanded {
			&::before {
				background: none;
			}
		}
	}
}

.sec-clientes-dos {
	.container-fluid {
		background: $black;
		display: flex;
		align-items: center;
		.carousel-control-prev-icon,
		.carousel-control-next-icon {
			width: 90px;
			height: 90px;
		}
		.inner-car {
			column-gap: 0;
		}
		div {
			flex: 1;
			&.s-slide-info {
				color: $white;
				text-align: left;
				flex: 0.5;
				h2 {
					font-size: 25px;
					font-weight: 700;
					max-width: 80%;
					margin: auto;
				}
				p {
					font-size: 17px;
					max-width: 80%;
					margin: auto;
				}
			}
		}
	}
}

#carousel-second,
#carousel-four,
#carousel-second,
#carousel-third,
#carousel-five {
	max-height: 472px;
	.slide-image {
		max-height: 472px;
		img {
			height: 500px;
			object-fit: cover;
			object-position: center;
		}
	}
}
.sec-ventajas {
	padding: 3rem 0 0;
	.col-lg-6 {
		margin-left: auto;
		margin-right: auto;
		margin-bottom: 3rem;
	}
	.col-lg-3 {
		margin-right: 1rem;
		flex: 0 0 25%;
		max-width: 23%;
		&:nth-last-of-type {
			margin-right: 0;
		}
		h4 {
			font-size: $font-size-20;
			font-weight: 100;
		}
	}
}

.bg-primary {
	background: $bg-pink-1;
}

.bg-textura {
	p:first-of-type {
		width: 70%;
		margin-bottom: 3rem;
	}
}

.sec-banner-fullwidth {
	h2 {
		@include line-subtitle();
		color: $brand-secondary;
		font-size: $font-size-45;
		display: inline-flex;
		font-weight: 600;
		letter-spacing: -1.39px;
		margin: auto;
		text-transform: uppercase;
		padding: 1.25rem 0 0.8rem;
	}

	&.bg-primary {
		background: $color-primary url(#{$_image-path}/bkg/fedi-transp.jpg) no-repeat center bottom;
		background-size: cover;
		padding: 2.7rem 0;
		margin: 3rem 0;

		p {
			@include txt-font-20($white, 700);
			letter-spacing: -0.94px;
		}

		h2 {
			@include txt-font-40($white, 700);
			text-transform: initial;
		}
	}

	&.bg-primary-ad {
		background: $color-primary url(#{$_image-path}/bkg/fedi-adu-blue.jpg) no-repeat center bottom;
		background-size: cover;
		padding: 2.7rem 0 3.7rem 0;
		margin: 3rem 0;

		p {
			@include txt-font-20($white, 700);
			letter-spacing: -0.94px;
		}

		h2 {
			color: $white;
		}
	}
}
.bg-textura {
	padding: 5rem 0;
}

.bg-blue-dark {
	background-color: $brand-primary;
}

.intro-default {
	background: $white-3;
}

.sec-reto-top,
.empresas-servicios-info {
	.quienes-text {
		margin: 2.5rem 0;
	}
	.row {
		justify-content: space-around;
		.on-item {
			max-width: 50%;
			padding: 0;
			@media all and (max-width: map-get($grid-breakpoints, "sm") - 1) {
				max-width: 100%;
			}
			&:first-of-type {
				padding-right: 38px;
			}
			&:last-of-type {
				padding-left: 38px;
			}
		}
		.serv-desc {
			text-align: left;
			.subtitle-line {
				margin-bottom: 0;
				padding-bottom: 0;
				line-height: 0.9;
				text-transform: initial;
				font-size: $font-size-40;
			}
		}
	}
}
.empresas-servicios-info {
	padding: 5rem 0;
	.on-item {
		&:last-of-type {
			img {
				padding-top: 28px;
			}
			.serv-desc {
				p {
					margin-top: 3.8rem;
				}
			}
		}
		&:first-of-type {
			.serv-desc {
				p {
					margin-top: 2rem;
				}
			}
		}
	}
}

.sec-metodologia {
	.container-fluid {
		max-width: 100%;
		.col-lg-6 {
			background: $bg-gray;
			text-align: left;
			padding: 2rem 2rem 2rem 8rem;
			@media all and (max-width: map-get($grid-breakpoints, "sm") - 1) {
				max-width: 100%;
				width: 100%;
				padding: 2rem;
			}
			.serv-list {
				text-align: left;
				margin: initial;
				padding-left: 1em;
				li {
					margin-bottom: 14px;
					list-style: none;
					font-size: 21px;
					&::before {
						content: "\2022";
						color: $brand-accent;
						font-weight: bold;
						display: inline-block;
						width: 1em;
						margin-left: 1em;
					}
				}
			}
			h3 {
				color: $color-secondary;
				margin-bottom: 1rem;
			}
		}
		.back-img {
			background: url(#{$_image-path}/bkg/fedi-servi-image.jpg) no-repeat center bottom;
			background-size: cover;
		}
	}
}
.sec-como-funciona {
	.sec-clientes {
		color: $white;
		padding: 0;
		h3 {
			font-size: 21px;
		}
	}
}
.sec-internet {
	background: $black;
	.col-lg-8 {
		margin: 0 auto;
		.subtitle-line {
			color: $white;
			font-size: 35px;
			text-transform: uppercase;
			font-weight: 800;
			margin-bottom: 1rem;
		}
	}
	.col-lg {
		min-height: 433px;
		background-size: cover !important;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: flex-end;
		h3 {
			font-size: 25px;
			font-weight: 700;
			color: $black;
		}
		p {
			font-size: 16px;
			color: $black;
			span {
				font-weight: 600;
			}
		}
	}
}
.sec-somos {
	.sec-servicio.alt {
		position: relative;
		padding-bottom: 4rem;
	}
}
.sec-iconos {
	.col-lg-12 {
		display: flex;
		justify-content: space-between;
		img {
			margin-bottom: 2rem;
			height: 124px;
			width: auto;
		}
	}
}
.sec-servicio.alt .contact-part {
	margin: 4rem 0;
	h2 {
		font-size: 30px;
		text-align: center;
		max-width: 680px;
		margin: 6rem auto 1rem;
	}
	a {
		margin: 0 auto;
	}
}
.sec-somos-gallery {
	margin: 4rem 0;
	h2 {
		font-size: 30px;
		text-align: center;
		max-width: 680px;
		margin: 6rem auto 1rem;
	}
	a {
		margin: 0 auto;
	}
	.container-fluid {
		max-width: 100%;
	}
	.col-lg-12 {
		display: flex;
		justify-content: space-between;
		.col-lg-4 {
			padding: 0;
		}
	}
}
.sec-servicio {
	.second-block {
		display: flex;
		gap: 2rem;
		justify-content: space-between;
		align-items: flex-end;
		padding: 4rem 0;
	}
	.col-lg-6 {
		h2 {
			padding-bottom: 0;
			font-size: 75px;
			font-weight: 900;
		}
		h4 {
			font-size: $font-size-25;
			font-weight: 100;
			padding-top: 0;
		}
		p {
			font-size: 17px;
		}
	}
	.col-lg-3 {
		margin-right: 1rem;
		flex: 0 0 25%;
		max-width: 23%;
		&:nth-last-of-type {
			margin-right: 0;
		}
		h4 {
			font-size: $font-size-20;
			font-weight: 100;
		}
	}
}

.sec-blog {
	main {
		&.content {
			background: $white;
			padding: 0 0 4rem;

			.blog-header {
				padding: 4rem 26rem 8rem;
				display: flex;
				flex-direction: column;
				align-items: center;
				background: $color-primary;
				color: $white;

				position: relative;
				&:before,
				&:after {
					content: "";
					position: absolute;
					left: 0;
					right: 0;
					bottom: 25px;
					height: 200px;
					background: radial-gradient(closest-side, $white, $white 63%, $color-primary 65%);
					background-size: 146px 90px;
					background-position: 0px 169px;
					background-repeat: repeat-x;
				}
				&:after {
					background: radial-gradient(closest-side, $color-primary, $color-primary 60%, $white 63%);
					background-size: 146px 90px;
					background-position: 73px -64px;
					background-repeat: repeat-x;
					bottom: -174px;
				}

				.search-wrapper {
					width: 70%;

					.search-input {
						border: 1px solid $white;
						border-radius: 100px;
						background: url(#{$_image-path}/bkg/sky-search.svg);
						background-repeat: no-repeat;
						background-position-x: 97%;
						padding: 0 2rem;
						background-position-y: 6px;
					}
				}

				h2 {
					text-transform: uppercase;
					margin-bottom: 2rem;
				}
			}

			.posts-item-dest {
				display: flex;
				position: absolute;
				top: -5rem;
				max-height: 340px;

				.dest-text {
					flex: 1;
					padding: 2rem;
					background: $contact-green;

					a {
						color: $black;
						margin-bottom: 2rem;
						display: block;
						font-size: 28px;
						font-weight: 700;
					}

					p {
						color: $white;
					}
				}

				.dest-im {
					flex: 1;

					img {
						width: 100%;
						height: 100%;
						object-fit: cover;
					}
				}
			}

			.content-wrapper {
				max-width: 1100px;
				margin: auto;
				display: flex;
				padding: 18rem 0 4rem;
				position: relative;

				#listing {
					width: 70%;
					display: grid;
					grid-template-columns: 4fr 4fr;
					column-gap: 1rem;

					.list-item {
						border-bottom: 4px solid $color-primary;
						margin-bottom: 2rem;
						padding-bottom: 1rem;

						.p-name {
							margin: 1rem 0 2rem;

							.list-blog-date {
								display: none;
							}

							a {
								color: $black;
								font-size: 20px;
							}
						}

						.p-summary {
							a {
								color: $contact-green;
							}
						}

						.tags {
							margin-top: 0.5rem;
							display: block;

							a {
								color: $contact-green;
							}
						}
					}
				}

				#sidebar {
					width: 30%;
					margin-left: 2rem;

					.search-wrapper {
						display: none;
					}

					.b-categories {
						padding: 1rem;
						background-color: $bg-gray;
						margin-bottom: 2rem;

						h4 {
							border-bottom: 1px solid $color-primary;
							padding-bottom: 1rem;
							margin-bottom: 1rem;
							font-size: 20px;
							color: $black;
						}

						.tags {
							display: block;

							a {
								display: block;
								color: $black;
							}
						}
					}

					.recent-post {
						background: $color-primary;
						padding: 1rem;

						h4 {
							color: $white;
							margin-bottom: 2rem;
							font-size: 20px;
						}

						ul {
							padding-left: 0;

							li {
								&::before {
									content: none;
								}

								list-style: none;
								border-top: 1px solid $white;
								padding: 1rem 0;
								display: flex;

								.imagencita {
									flex: 1;

									img {
										width: 124px;
										height: 72px;
									}
								}

								a {
									color: $white;
									font-weight: 100;
									flex: 1;
									margin-right: 1rem;
								}
							}
						}
					}
				}
			}
		}
	}
}

.blog-content-item {
	background: $white;

	#item {
		max-width: 1100px;
		margin: auto;
		display: flex;
		padding: 2rem 0;
		position: relative;

		.h-entry {
			flex: 1;

			.list-blog-header {
				padding-top: 650px;

				img {
					position: absolute;
					width: 100%;
					top: 3rem;
					max-height: 600px;
					object-fit: cover;
				}
			}

			a {
				color: $black;
				display: block;
				margin: 1rem 0;
				font-size: 20px;
			}

			hr {
				border: 2px solid $color-primary;
				width: 10%;
				margin-left: 0;
				margin-top: 2rem;
				margin-bottom: 2rem;
			}

			h4 {
				display: flex;
				align-items: center;
				justify-content: space-between;

				span {
					font-size: 12px;
					color: $light-black;
				}
			}
		}

		#sidebar {
			width: 30%;
			margin-left: 2rem;
			margin-top: 650px;

			.search-wrapper {
				margin-bottom: 2rem;

				.search-input {
					border: 1px solid $black;
					border-radius: 100px;
					background: url(#{$_image-path}/bkg/sky-search.svg);
					background-repeat: no-repeat;
					background-position-x: 97%;
					padding: 0 2rem;
					background-position-y: 6px;
					color: $contact-green;
				}
			}

			.b-categories {
				padding: 1rem;
				background-color: $bg-gray;
				margin-bottom: 2rem;
				color: $black;

				h4 {
					border-bottom: 1px solid $color-primary;
					padding-bottom: 1rem;
					margin-bottom: 1rem;
					font-size: 20px;
				}

				.tags {
					display: block;

					a {
						display: block;
						color: $black;
					}
				}
			}

			.recent-post {
				background: $color-primary;
				padding: 1rem;

				h4 {
					color: $white;
					margin-bottom: 2rem;
					font-size: 20px;
				}

				ul {
					padding-left: 0;

					li {
						&::before {
							content: none;
						}

						list-style: none;
						border-top: 1px solid $white;
						padding: 1rem 0;
						display: flex;

						.imagencita {
							flex: 1;

							img {
								width: 124px;
								height: 72px;
							}
						}

						a {
							color: $white;
							font-weight: 100;
							flex: 1;
							margin-right: 1rem;
						}
					}
				}
			}
		}
	}
}

#ventajas {
	.col-lg {
		background: $color-primary;
	}
	.row {
		align-items: flex-start;
		max-width: 1280px;
		margin-top: 4rem;
	}
}
